<template>
  <div class="summary">
    <table class="w100">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <tr v-if="type === 'whz' || type === 'muac'" v-html="$t('components.description.percentageOfOedema', {
        number: summary.n_edema,
        value: (summary && summary.percent_edema != null) ? summary.percent_edema.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 }) : ''
      })">
      </tr>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <tr v-html="$t('components.description.meanOfType', {
        type: translatedFilterTypes(type).toUpperCase(),
        number: (summary && summary.n) ? summary.n : '',
        value: (summary && summary.mean && !Number.isNaN(summary.mean)) ? Number(summary.mean).toFixed(2) : '',
        sd: (summary && summary.sd && !Number.isNaN(summary.sd)) ? Number(summary.sd).toFixed(2) : ''
      })">
      </tr>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <tr v-if="type !== 'muac'" v-html="$t('components.description.designEffectGraph', {
        type: translatedFilterTypes(type).toUpperCase(),
        number: -2,
        value: (summary && summary.deff && !Number.isNaN(summary.deff)) ? Number(summary.deff).toFixed(2) : ''
      })">
      </tr>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <tr v-if="type === 'muac'" v-html="$t('components.description.designEffectGraph', {
        type: translatedFilterTypes(type).toUpperCase(),
        number: muacUpr,
        metric: $t('values.mm'),
        value: (summary && summary.deff && !Number.isNaN(summary.deff)) ? Number(summary.deff).toFixed(2) : ''
      })">
      </tr>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <tr v-if="type !== 'muac'" v-html="$t('components.description.zScoresNotAvailable', {
        value: (summary && summary.zscores_not_available != null) ? summary.zscores_not_available : ''
      })">
      </tr>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <tr v-if="type !== 'muac'" v-html="$t('components.description.zScoresOutOfRange', {
        value: (summary && summary.zscores_out_of_range != null) ? summary.zscores_out_of_range : ''
      })">
      </tr>
    </table>
  </div>
</template>

<script>
import { translatedFilterTypes } from '@/util/indicators';

export default {
  name: 'AnthropometryResultsSummary',
  props: {
    summary: {
      type: Object,
      default: undefined
    },
    type: {
      type: String,
      required: true
    },
    surveyInfo: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    muacUpr() {
      if (this.surveyInfo && this.surveyInfo.settings && this.surveyInfo.settings.muac_upr != null) {
        return this.surveyInfo.settings.muac_upr;
      }
      return '';
    }
  },
  methods: {
    translatedFilterTypes
  }
};
</script>

<style scoped>
.summary {
  padding: 20px;
  background: rgba(203, 199, 199, 0.1);
  border: solid 1px rgba(151, 151, 151, 0.46);
}
table {
  table-layout: fixed;
}
table >>> td:nth-child(2) {
  width: 20px;
}

table >>> td:nth-child(1) {
  width: 180px;
}
</style>
