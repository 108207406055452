var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"summary"},[_c('table',{staticClass:"w100"},[(_vm.type === 'whz' || _vm.type === 'muac')?_c('tr',{domProps:{"innerHTML":_vm._s(_vm.$t('components.description.percentageOfOedema', {
      number: _vm.summary.n_edema,
      value: (_vm.summary && _vm.summary.percent_edema != null) ? _vm.summary.percent_edema.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 }) : ''
    }))}}):_vm._e(),_c('tr',{domProps:{"innerHTML":_vm._s(_vm.$t('components.description.meanOfType', {
      type: _vm.translatedFilterTypes(_vm.type).toUpperCase(),
      number: (_vm.summary && _vm.summary.n) ? _vm.summary.n : '',
      value: (_vm.summary && _vm.summary.mean && !Number.isNaN(_vm.summary.mean)) ? Number(_vm.summary.mean).toFixed(2) : '',
      sd: (_vm.summary && _vm.summary.sd && !Number.isNaN(_vm.summary.sd)) ? Number(_vm.summary.sd).toFixed(2) : ''
    }))}}),(_vm.type !== 'muac')?_c('tr',{domProps:{"innerHTML":_vm._s(_vm.$t('components.description.designEffectGraph', {
      type: _vm.translatedFilterTypes(_vm.type).toUpperCase(),
      number: -2,
      value: (_vm.summary && _vm.summary.deff && !Number.isNaN(_vm.summary.deff)) ? Number(_vm.summary.deff).toFixed(2) : ''
    }))}}):_vm._e(),(_vm.type === 'muac')?_c('tr',{domProps:{"innerHTML":_vm._s(_vm.$t('components.description.designEffectGraph', {
      type: _vm.translatedFilterTypes(_vm.type).toUpperCase(),
      number: _vm.muacUpr,
      metric: _vm.$t('values.mm'),
      value: (_vm.summary && _vm.summary.deff && !Number.isNaN(_vm.summary.deff)) ? Number(_vm.summary.deff).toFixed(2) : ''
    }))}}):_vm._e(),(_vm.type !== 'muac')?_c('tr',{domProps:{"innerHTML":_vm._s(_vm.$t('components.description.zScoresNotAvailable', {
      value: (_vm.summary && _vm.summary.zscores_not_available != null) ? _vm.summary.zscores_not_available : ''
    }))}}):_vm._e(),(_vm.type !== 'muac')?_c('tr',{domProps:{"innerHTML":_vm._s(_vm.$t('components.description.zScoresOutOfRange', {
      value: (_vm.summary && _vm.summary.zscores_out_of_range != null) ? _vm.summary.zscores_out_of_range : ''
    }))}}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }